<template>
  <div>
    <h3>Upcoming Renewals</h3>
    <p-datatable :value="reportData" rowGroupMode="subheader" groupRowsBy="subscription.client.name"
                 sortMode="single" sortField="subscription.client.name" :sortOrder="1" >
      <template #groupheader="slotProps">
        <span class="fw-bold">{{ slotProps.data.subscription.client.name }}</span>
      </template>
      <p-column field="subscription.client.name" header="Client"></p-column>
      <p-column field="subscription.name" header="Subscription Name"></p-column>
      <p-column field="start_date" header="Start Date"></p-column>
      <p-column field="term_days" header="Term (Days)"></p-column>
      <p-column field="end_date" header="End Date"></p-column>
      <p-column header="Owner">
        <template #body="slotProps">
          {{ primaryInternalContact(slotProps.data) }}
        </template>
      </p-column>
      <p-column header="Days Left">
        <template #body="slotProps">
          <p-tag :value="dateDiff(slotProps.data.end_date, new Date)" :severity="daysLeftSeverity(slotProps.data.end_date, new Date)"></p-tag>
        </template>
      </p-column>
    </p-datatable>
  </div>
</template>

<script>
import ReportMixin from './_report_mixin'

import Datatable from 'primevue/datatable';
import Column from 'primevue/column';
import Tag from 'primevue/tag';
import moment from 'moment';

export default {
  mixins: [ReportMixin],
  components: {
    'p-datatable': Datatable,
    'p-column': Column,
    'p-tag': Tag,
  },
  data() {
    return {
      moment
    }
  },
  methods: {
    primaryInternalContact(contract) {
      let primary_contact = contract.subscription.contact_priorities.filter(x => x.contact_priority === "primary")[0]
      return primary_contact?.contact?.email
    },
    dateDiff(a, b) {
      let aMoment = this.moment(a);
      let bMoment = this.moment(b);
      return `${aMoment.diff(bMoment, 'days')} days`
    },
    daysLeftSeverity(a, b) {
      let aMoment = this.moment(a);
      let bMoment = this.moment(b);
      let days = aMoment.diff(bMoment, 'days');

      if (days > 60) { return 'info' }
      else if (days > 30) { return 'warning' }
      else { return 'danger' }
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>

</style>